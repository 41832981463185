body {
  margin: 0;
  background-color: #eee;
  color: #666;
  font-size: "14px";
  height: 100%;
  width: 100%;
}

.ql-size {
  text-align: center;
}

.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}

.ql-size .ql-picker-item[data-value="15px"]::before {
  content: "15px";
  font-size: 15px !important;
}

.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}

.ql-snow .ql-tooltip[data-mode=link]::before,
.ql-snow .ql-tooltip::before {
  content: "URL:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "保存";
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: "編集";
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: "削除";
}
